const fa = {
  store: {
    title: "فروشگاه",
    category: "دسته بندی ها",
    menus: "دسته بندی اصلی",
    mainCategory: "دسته بندی فرعی",
    categories: {
      title: "دسته بندی",
      create: "ایجاد",
      edit: "ویرایش",
    },
    pageInfo: {
      title: "صفحات",
      titleList: "لیست صفحات",
      titleCategory: "دسته بندی صفحات",
    },
    products: {
      title: "محصولات",
      list: "لیست محصولات",
      detail: "ویژگی محصولات",
      topics: "سرفصل",
      attribute: {
        title: "ویژگی محصولات",
        create: "افزودن",
      },
    },
    roles: {
      title: "نقش ها",
    },
    categoryUpload: {
      title: "دسته بندی اپلود فایل",
    },
    upload: {
      title: "اپلود فایل",
    },
    profiles: {
      title: "پروفایل",
      list: "تعاریف سطح دسترسی",
    },
    crm: {
      title: "سطح دسترسی کاربران crm",
      list: "سطح دسترسی کاربران crm",
    },
    crmContacts: {
      title: "سطح دسترسی مخاطبین crm",
      list: "سطح دسترسی مخاطبین crm",
    },
    comments: {
      title: "کامنت ها",
    },
    hiring: { title: "آگهی" },
    topic: {
      title: "سرفصل",
    },
    permission: {
      title: "دسترسی",
    },
    group: {
      title: "گروه",
    },
    cart: {
      title: "سبد خرید",
    },
    users: {
      title: "کاربران",
      titleUser: "فایل آپلود شده",
      userAttributes: "ویژگی کاربران",
      userReferrals: "کاربران دعوت شده",
    },
    modules: {
      title: "سطح دسترسی ماژول ها",
    },
    license: {
      title: "گواهی نامه ها",
      list: "لیست گواهی ها",
      productList: "محصولات دارای گواهی",
    },
    consult: {
      title: "مدیریت فرم ها",
    },
    transaction: {
      title: "امور مالی",
    },
    landings: {
      title: "لندینگ ها",
      create:"ساخت لندینگ",
      edit:"به روز رساتی لندیگ"
    },
    reports: {
      title: "گزارش ها",
      login:{
        title:"گزارش لاگین"
      },
      ticket:{
        title:"گزارش تیکت"
      }
    },
    meetRooms: {
      title: "جلسات انلاین",
    },
    campaign: {
      title: "کمپین",
    },
    faq: {
      title: "سوالات متداول محصولات",
      list: "لیست سوالات",
      category: "دسته بندی سوالات",
    },
    faqProfile: {
      title: "سوالات متداول ",
      list: "لیست سوالات",
      category: "دسته بندی سوالات",
    },
    questionSurvey: {
      title: "سوالات نظرسنجی",
    },
    ticket: {
      title: "لیست تیکت ها",
      placeHolder: "ارسال پاسخ تیکت",
      closedPlaceHolder: "تیکت بسته شده است",
      cancel: "انصراف",
      close: "بستن تیکت",
      update: "بروزرسانی",
      status: "وضعیت",
      ticketNumber: "شماره تیکت",
      seenBy: "دیده شده توسط",
      editMode: "حالت ادیت",
      sendFile: "ارسال فایل",
      filterSearchPlaceholder: "جستجوی شماره تیکت یا موضوع",
    },
    categoryTicket: {
      title: "دسته بندی تیکت ها",
    },
    lms: {
      title: "LMS",
      titleLms: "لیست امتحان ها",
      titleIELTS: "لیست ازمون آیلتس",
      lmsLicense: "لیست گواهینامه",
    },
    userDoc: {
      title: "دسته بندی اسناد",
    },
    common: {
      create: "افزودن",
      edit: "ویرایش",
      delete: "حذف",
      back: "بازگشت",
      upload: "بارگذاری",
      confirmNew: "ثبت",
      confirmSave: "ذخیره",
      confirmEdit: "ذخیره",
      confirmSaveEdit: "ذخیره و خروج",
      refresh: "تازه سازی",
    },
    media: {
      title: "رسانه",
    },
  },
  dashboard: {
    title: "داشبورد",
    tableCommon: {
      rowPerPage: "ردیف در هر صفحه",
      noData: "داده ای یافت نشد",
      saveButton: "ذخیره تغییرات",
      dense: "متراکم",
      view: "مشاهده",
      edit: "ویرایش",
      delete: "حذف",
    },
  },
  news: {
    title: "اطلاعیه",
    titleNews: "نوشتن اطلاعیه",
    categoriesNews: "دسته بندی اطلاعیه",
    TagNews: "لیست اطلاعیه ها",
  },
  blog: {
    title: "دانشنامه",
    blog: {
      title: "بلاگ",
      titleBlog: "نوشتن بلاگ",
      categoriesBlog: "دسته بندی بلاگ",
      TagBlog: "لیست تگ ها",
    },
  },
  supplier: {
    management: {
      title: "پنل مدیریت",
      settings: {
        title: "مشخصات تامین کننده",
        settings: {
          title: "مشخصات",
          confirm: "ثبت مشخصات",
        },
      },
      search: "جست و جو .....",
      users: {
        search: "جست و جو بر اساس اسم فارسی ...",
      },
      products: {
        title: "محصولات",
        catsList: {
          title: "لیست دسته بندی ها",
          create: "افزودن دسته بندی",
          edit: "ویرایش دسته بندی",
          search: "جست و جو نام دسته بندی ...",
        },
        productsList: {
          title: "لیست محصولات",
          create: "افزودن محصول",
          edit: "ویرایش محصول",
          search: "جست و جو نام محصول ...",
        },
        productsOrderList: {
          title: "ترتیب محصولات",
        },
      },
      personalStore: {
        title: "فروشگاه اختصاصی",
        storeSettings: {
          title: "مشخصات",
          confirm: "ثبت مشخصات",
        },
        discounts: {
          title: "کد تخفیف فروشگاه اختصاصی",
          create: "ایجاد کد تخفیف",
          edit: "ویرایش کد تخفیف",
          search: "جست و جو کد تخفیف ...",
        },
        orders: {
          title: "سفارشات فروشگاه اختصاصی",
          edit: "ویرایش سفارش",
          detail: "مشاهده سفارش",
          detailPage: "جزئیات سفارش",
          search: "جست و جو نام کاربر یا شماره فاکتور ...",
        },
      },
      stores: {
        title: "فروشگاه ها",
        storesList: {
          title: "لیست فروشگاه ها",
          create: "ایجاد فروشگاه",
          edit: "ویرایش فروشگاه",
          search: "جست و جو نام فروشگاه ...",
        },
      },
      storesSite: {
        title: "سایت فروشگاه ها",
        campaign: {
          title: "کمپین ها",
          create: "ایجاد کمپین",
          edit: "ویرایش کمپین",
          search: "جست و جوی نام کمپین ...",
        },
        slider: {
          title: "اسلایدرها",
          create: "افزودن اسلایدر",
          edit: "ویرایش اسلایدر",
          slide: {
            title: "اسلایدها",
            create: "افزودن اسلاید",
            edit: "ویرایش اسلاید",
          },
        },
        discounts: {
          title: "کد تخفیف دیگر فروشگاه ها",
          create: "ایجاد کد تخفیف",
          edit: "ویرایش کد تخفیف",
          search: "جست و جو کد تخفیف ...",
        },
        orders: {
          title: "سفارشات دیگر فروشگاه ها",
          edit: "ویرایش سفارش",
          detail: "مشاهده سفارش",
          detailPage: "جزئیات سفارش",
          search: "جست و جو نام کاربر یا شماره فاکتور ...",
        },
      },
    },
  },
};

export default fa;
